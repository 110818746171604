<template>
  <v-container id="about" fluid tag="section">
    <v-card class="px-5 py-3" style="margin-top:10px">
      <v-card-text>
        <v-row>
          <v-col>
            <p class="font-weight-light grey--text">本サイトについて</p>
            <p>
              本サイトは厚労省のサイトに掲載されている新型コロナワクチンの副反応疑い報告を効率的に確認するために作成しております。個人運営サイトです。
              <br />要望などがございましたら、お問い合わせからご連絡ください。些細なことでも構いませんのでご協力いただけると幸いです。
            </p>

            <p class="font-weight-light grey--text" style="margin-top : 50px">データについて</p>
            <p>
              本サイトで利用しているデータは、厚生科学審議会予防接種・ワクチン分科会副反応検討部会のPDF資料に基づいています。
              <br />PDFから機械的にデータを抽出しているため完全性を保障することはできません。
              <br />データの利用については自己責任でお願いいたします。欠損データや表示崩れが起きているデータは把握次第、順次修正していきます。
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="px-5 py-3" style="margin-top:10px">
      <v-card-text>
        <v-row>
          <v-col>
            <p class="font-weight-light grey--text">更新履歴</p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="20%">日付</th>
                    <th class="text-left">更新内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in updates" :key="item.name">
                    <td>{{ item.date }}</td>
                    <td>{{ item.text }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      updates: [],
    }
  },

  mounted() {
    this.getJson()
  },

  methods: {
    getJson() {
      var jsonFileName = 'updates.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          this.updates = res.data
        }.bind(this),
      )
    },
  },
}
</script>
